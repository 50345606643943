import {
  REFRESH_ROUTE,
  validate,
} from '@immediate_media/auth-utils';
import { logger } from './logger.js';

/**
 * Split cookie into an object.
 * @returns {object} the object containing the cookies
 */
const getCookies = () => document.cookie?.split(';').reduce((acc, cookieItem) => {
  const [key, value] = cookieItem.trim().split('=');
  acc[key] = value;
  return acc;
}, {});

/**
 * Pushes the external JWT into the tp object.
 * @param {string} token the JWT token
 */
const setExternalJWT = (token) => {
  tp.push(['setExternalJWT', token]);
  logger.debug('JWT sent to Piano', token);
};

/**
 * Refreshes the authentication tokens for the user.
 * @returns {Promise<boolean>} the success state of the refresh tokens request
 */
const refreshAuthTokens = () => new Promise((resolve) => {
  const refreshUrl = new URL(REFRESH_ROUTE, window.location.href).href;
  logger.debug('Attempting to refresh tokens so we can send JWT to Piano');

  fetch(refreshUrl).then((res) => {
    if (!res.ok) {
      logger.debug('Refresh authentication tokens was unsuccessful');

      resolve(false);
    }

    resolve(true);
  }).catch((err) => {
    logger.error(err);

    resolve(false);
  });
});

/**
 * Setup JWT authentication.
 *
 * Ensure a fallback by decoding JWT and ensuring the expiry is later than now before sending to
 * Piano.
 * Where the token is expired a refresh of the token is attempted. If refresh is successful the
 * token is sent to Piano.
 * @returns {Promise}
 */
export const setupAuth = () => new Promise((resolve) => {
  const { id_token: idToken } = getCookies();
  if (!idToken) {
    resolve();
  }

  if (validate(idToken)) {
    setExternalJWT(idToken);

    resolve();
  } else {
    refreshAuthTokens().then((hasRefreshedTokens) => {
      if (!hasRefreshedTokens) {
        resolve();
      }

      const { id_token: refreshedIdToken } = getCookies();
      if (refreshedIdToken && validate(refreshedIdToken)) {
        setExternalJWT(refreshedIdToken);
      }

      resolve();
    });
  }
});

/**
 *
 * Redirects user to correct authentication journey.
 */
export const authRedirect = (endpoint) => {
  const authUrl = new URL(endpoint, window.location.origin);

  authUrl.searchParams.set('redirect', window.location.href);
  window.location.assign(authUrl.href);
};

export const ACCESS_TOKEN_COOKIE_NAME = 'access_token';
export const ID_TOKEN_COOKIE_NAME = 'id_token';
export const REFRESH_TOKEN_COOKIE_NAME = 'refresh_token';

// These cookies are used to trigger login/logout GTM events across services
export const LOGIN_EVENT_COOKIE_NAME = 'IMOICLoginEvent';
export const LOGOUT_EVENT_COOKIE_NAME = 'IMOICLogoutEvent';

// The routes served by https://github.immediate.co.uk/WCP-Services/im-platform-openid-connect
export const LOGIN_ROUTE = '/auth/login';
export const LOGOUT_ROUTE = '/auth/logout';
export const REGISTER_ROUTE = '/auth/register';
export const REFRESH_ROUTE = '/auth/refresh';

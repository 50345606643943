import decode from 'jwt-decode';

/**
 * Check if a JWT token can be decoded and has not expired.
 *
 * Note that the refresh call will also be made for invalid tokens so that the
 * auth service can remain responsible for clearing the cookies correctly.
 */
export const validate = (token) => {
  let decodedToken;

  try {
    decodedToken = decode(token);
  } catch (error) {
    throw new Error('Failed to decode JWT token');
  }

  const { exp } = decodedToken;
  const secondsNow = Math.floor(Date.now() / 1000);

  if (!exp) {
    throw new Error('Missing "exp" claim for JWT token');
  }

  return exp > secondsNow;
};
